import { render, staticRenderFns } from "./RemarkTemplate.vue?vue&type=template&id=1a44a2ad&scoped=true&"
import script from "./RemarkTemplate.vue?vue&type=script&lang=js&"
export * from "./RemarkTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a44a2ad",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a44a2ad')) {
      api.createRecord('1a44a2ad', component.options)
    } else {
      api.reload('1a44a2ad', component.options)
    }
    module.hot.accept("./RemarkTemplate.vue?vue&type=template&id=1a44a2ad&scoped=true&", function () {
      api.rerender('1a44a2ad', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Papers/pages/Paper/components/Templates/RemarkTemplate.vue"
export default component.exports